export const environment = {
  production: false,
  baseHref: '/',
  redirectUri: 'https://seasonal-storage-ui.qas.ag2goservices.amgreetings.com/',
  apiScopeUrl: 'api://7fea34a1-ade5-440e-bd2e-e11e9b1e6d51/access_as_user',
  clientId: 'a47fb17b-d2fb-4b44-a074-d3e2ab2efdbe',
  postLogoutRedirectUri: 'https://seasonal-storage-ui.qas.ag2goservices.amgreetings.com/',
  webApi: 'https://seasonal-storage.qas.ag2goservices.amgreetings.com/api',
  userRolesAuthorization: false,
  tablePaginationSizes: [10, 25, 50, 75, 100, 150, 200, 250, 500, 1000, 2000],
  pageableAllItems: 99999,
  noLoadingSpinnerHeader: 'loading-spinner-disabled',
  httpRequestChunkSize: 100,
};
